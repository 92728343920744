<template>
  <div>Идет процесс выхода из приложения</div>
</template>

<script>
  export default {
    name: "logOut",
    mounted() {
      localStorage.removeItem('SessionKey');
      localStorage.removeItem('UserID');
      this.$store.commit('resetState');
      this.$store.commit('setSessionKey', '');
      this.$store.commit('setUserID', '');
      this.$store.commit('repeatLoan/resetState');
      if (localStorage.getItem('setDlCode') == 'true') {
        this.$router.push('/login/nsb');
      } else {
        this.$router.push('/login');
      }
    }
  }
</script>

<style scoped>

</style>
